import React from "react";

function Footer() {
  return (
    <div className="pt-20 pr-8 pb-1 text-sm text-center md:text-right text-[#989898]">
      This site is a work in progress
    </div>
  );
}

export default Footer;
