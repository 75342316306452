import React from "react";

export default function Startups() {
  const startups = [
    {
      year: "2018",
      name: "TowardsBlockchain",
      about: "Blockchain product company",
      description:
        "Docusign on the blockchain, KYC wallet, Facial recognition attendance system, crypto exchange, Football NFT marketplace for one of the football clubs of Brazil, etc.",
      reason:
        "Onboarded too many co-founders, focus shifted from product to services",
    },
    {
      year: "2020",
      name: "Zubi",
      about: "Lambda school for India focused on emerging technologies",
      description:
        "Bootstrapped, Hired 10+ team members, 12k+ app downloads, Built 3k+ student community, ZubiCon (Emerging tech conference, 8k+ participants attended)",
      reason: "Challanges with Income share agreement in India",
    },
    {
      year: "2022",
      name: "Moving",
      about: " Crypto news and vertical social investing platform",
      description:
        "Raised pre-seed round, 40k+ app downloads, 5k+ DAU launched 3-4 micro drops, Hired 10+ team members",
      reason:
        "Crypto taxation in India made users adopt DEX while our solution was built on CEX, co founder did not want to build the platform on DEX, parted ways",
    },
  ];

  let works = [
    {
      id: 1,
      imgSrc: "p1.avif",
    },
    {
      id: 2,
      imgSrc: "p2.avif",
    },
    {
      id: 3,
      imgSrc: "p3.avif",
    },
    {
      id: 4,
      imgSrc: "p4.avif",
    },
  ];
  return (
    <div>
      <div className="uppercase pt-8 pb-4 font-bold">
        Startups that couldn't work as a Founder/Co-founder
      </div>
      <div className="text-md">
        {startups.map((startup, index) => (
          <div key={index} className="-mb-4">
            <span>
              &#10914; {startup.name} ({startup.year}) : {startup.about}
            </span>

            <p className="mt-2">{startup.description}</p>
            <p className="mt-2">Reason : {startup.reason}</p>
            <br />
            <br />
          </div>
        ))}
        <div className="columns-2 md:columns-2 py-6">
          {works.map((work, index) => {
            return (
              <div
                className="p-1 transition-all duration-300 hover:scale-110 "
                key={index}
              >
                <a href={work.imgSrc} target="_blank" rel="noopener noreferrer">
                  <img src={work.imgSrc} alt="Proof of work images" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
