import React from "react";

function Pic() {
  return (
    <div className="pb-8">
      <img
        src="pic.jpg"
        alt="Aman Sanduja"
        className="pic rounded-full m-auto hover:filter-none"
        width={200}
      />
    </div>
  );
}

export default Pic;
