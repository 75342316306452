import React from "react";
import Pic from "./Pic";
import Socials from "./Socials";
import About from "./About";
import Info from "./Info";
import Startups from "./Startups";
import SideHustles from "./SideHustles";
import Extra from "./Extra";
import Corporate from "./Corporate";

function Hero() {
  return (
    <>
      <div className="px-8 max-w-3xl mx-auto z-20 w-full">
        <div className="name font-bold text-5xl pb-8 text-center">
          Aman Sanduja
        </div>
        <Pic />
        <Socials />
        <Info />
        <About />
        <Startups />
        <SideHustles />
        <Corporate />
        <Extra />
      </div>
    </>
  );
}

export default Hero;
