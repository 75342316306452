import React from "react";

function Info() {
  return (
    <div>
      <div className="info text-md pt-6">
        <div className="text-center">
          <span className="italic text-center">
            Attention is the most scarce thing in the world!
          </span>
        </div>
        <br />
        <br />
        I'm trying again after failing three times. This time feels different
        because I'm clearer, more disciplined, and have better endurance. I'm
        interested in AI, Web3, education, indoor sports, startups, and
        philosophy. Right now, I'm building an emerging tech studio a9a where we
        create products using emerging tech. These products could improve
        people's lives and help them become more skilled and wealthy.
        <br />
        <br />
        The dream is to make a space that feels like home, both practically and
        emotionally.
      </div>
    </div>
  );
}

export default Info;
