import React from "react";

function SideHustles() {
  const experiments = [
    {
      name: "Iamcollaboration  : ",
      description: "Startup directory and collaboration platform",
    },
    {
      name: "BhandaraOnWheels  : ",
      description:
        "Mobile kitchen service that is providing healthy, nutritious, wholesome meals for food distribution",
    },
    { name: "Elevator  : ", description: "Patreon for India" },
    { name: "Munch  : ", description: "Crypto newsletter (3k+ daily readers)" },
    { name: "Plug  : ", description: "Credit card for creators" },
    { name: "Moveon  : ", description: "Back-in-time crypto calculator" },
    { name: "CTL  : ", description: "Crypto fantasy league" },
    { name: "", description: "Sold computer hardware and gaming CDs" },
  ];

  return (
    <div>
      <div className="uppercase pt-8 pb-2 font-bold">
        Built many side projects
      </div>
      <div className="text-md">
        Some experiments alongside building main products with a goal to
        reproduce vibes:
        <br />
        <br />
        <ul className="list-none">
          {experiments.map((experiment, index) => (
            <li key={index} className="flex ">
              <span className="mr-2">&#10914;</span>
              {experiment.name}
              {experiment.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SideHustles;
