import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";

function App() {
  return (
    <div className="main bg-white text-md font-abc ">
      {/* <div className="aurora min-h-screen">
        <div className="aurora-overlay z-10"></div>
      </div> */}

      <div className="absolute top-0 m-auto w-full mt-[4rem]">
        <Header />
        <Hero />
        <Footer />
      </div>
    </div>
  );
}

export default App;
