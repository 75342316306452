import React from "react";

function Socials() {
  return (
    <div>
      <div className="socials py-4 flex flex-row gap-8 items-center justify-center">
        <a
          className="pr-4 hover:opacity-50"
          href="https://twitter.com/amansanduja"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22px"
            height="22px"
            viewBox="0 0 1200 1227"
            fill="none"
          >
            <g>
              <path
                d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect width="1200" height="1227" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <a
          className="pr-4 hover:opacity-50"
          href="https://www.linkedin.com/in/amansanduja/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 56.693 56.693"
            width="30px"
            height="30px"
          >
            <g>
              <path d="M30.071,27.101v-0.077c-0.016,0.026-0.033,0.052-0.05,0.077H30.071z" />
              <path d="M49.265,4.667H7.145c-2.016,0-3.651,1.596-3.651,3.563v42.613c0,1.966,1.635,3.562,3.651,3.562h42.12   c2.019,0,3.654-1.597,3.654-3.562V8.23C52.919,6.262,51.283,4.667,49.265,4.667z M18.475,46.304h-7.465V23.845h7.465V46.304z    M14.743,20.777h-0.05c-2.504,0-4.124-1.725-4.124-3.88c0-2.203,1.67-3.88,4.223-3.88c2.554,0,4.125,1.677,4.175,3.88   C18.967,19.052,17.345,20.777,14.743,20.777z M45.394,46.304h-7.465V34.286c0-3.018-1.08-5.078-3.781-5.078   c-2.062,0-3.29,1.389-3.831,2.731c-0.197,0.479-0.245,1.149-0.245,1.821v12.543h-7.465c0,0,0.098-20.354,0-22.459h7.465v3.179   c0.992-1.53,2.766-3.709,6.729-3.709c4.911,0,8.594,3.211,8.594,10.11V46.304z" />
            </g>
          </svg>
        </a>
        <a
          className="pr-4 hover:opacity-50"
          href="mailto:amansanduja04@gmail.com"
        >
          <svg
            role="img"
            viewBox="0 0 24 24"
            width="28px"
            height="28px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title />
            <path d="M24 4.5v15c0 .85-.65 1.5-1.5 1.5H21V7.387l-9 6.463-9-6.463V21H1.5C.649 21 0 20.35 0 19.5v-15c0-.425.162-.8.431-1.068C.7 3.16 1.076 3 1.5 3H2l10 7.25L22 3h.5c.425 0 .8.162 1.069.432.27.268.431.643.431 1.068z" />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default Socials;
