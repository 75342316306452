import React from "react";

function About() {
  return (
    <div>
      <div className="uppercase pt-8 pb-2 font-bold">About Me</div>
      <div className="text-md">
        I have a strong passion for Web3 and AI, particularly their impact
        multiple industries, despite being an engineer by education, more by
        chance than by choice as I'm not particularly fond of coding.
        <br />
        <br />
        With over 9 years of experience in both startup and corporate
        environments.
        <br />
        <br />
        My entrepreneurial journey began after attending the Stanford
        Entrepreneurship Program in New Delhi, which was my escape route after
        achieving an average GPA in college. I've further honed my skills by
        consulting for startups backed by YC and AllianceDAO, assisting them in
        scaling from inception to significant milestones.
        <br />
        <br />I have a keen interest in tech products and thrive on getting
        involved in projects at an early stage. Beyond technology, I have a deep
        passion for the arts, exploring ventures such as a decentralized movie
        studio, a 3D NFT art collection, and a Patreon-like platform for artists
        in Tier 2 cities of India, among others.
      </div>
      <div className="uppercase pt-8 pb-2 font-bold">Web3!</div>
      <div className="text-md">
        In 2018, I took the leap into cryptocurrencies after leaving my 9-to-6
        corporate gig. It's kind of funny how I stumbled upon "
        <a
          className="underline hover:text-[grey] hover:no-underline underline-offset-2"
          href="https://www.youtube.com/watch?v=tmxqlSevtkQ"
          target="_blank"
          rel="noreferrer"
        >
          Banking on Bitcoin
        </a>
        " (end up watching it 5 times) and was completely sold on why this would
        be the next major shift in financial technology history.
      </div>
    </div>
  );
}

export default About;
