import React from "react";

function Extra() {
  let photos = [
    {
      id: 1,
      imgSrc: "1.avif",
    },
    {
      id: 2,
      imgSrc: "3.avif",
    },
    {
      id: 3,
      imgSrc: "2.avif",
    },
    {
      id: 4,
      imgSrc: "4.avif",
    },
    {
      id: 5,
      imgSrc: "5.avif",
    },
  ];
  let talks = [
    {
      id: 1,
      imgSrc: "t1.avif",
    },
    {
      id: 2,
      imgSrc: "t2.avif",
    },
    {
      id: 3,
      imgSrc: "t3.avif",
    },
    {
      id: 4,
      imgSrc: "t4.avif",
    },
    {
      id: 5,
      imgSrc: "t5.avif",
    },
    {
      id: 6,
      imgSrc: "t6.avif",
    },
  ];
  return (
    <div>
      <div className="uppercase pt-8 pb-2 font-bold">Teaching</div>
      <div className="text-md">
        Teaching is my passion! I love it.
        <br />
        Since, 2018, I have taught over 10k students & professionals on Crypto,
        Defi, NFTs etc at organizations like JIO gen academy, Upgrad, Barclays
        Bank, Accenture, KPMG, Deloitte, IITs, IIMs, and more. I am on the board
        of Amity and Bennett University.
        <br />
        <br />
        Topics I have taught: Defi, Metaverse, NFTs, Crypto trading, and more.
        <br />
        <br />
        Also a{" "}
        <a
          className="underline hover:text-[grey] hover:no-underline underline-offset-2"
          href="https://www.youtube.com/watch?v=qPNVon5so9Y"
          target="_blank"
          rel="noreferrer"
        >
          TedX speaker
        </a>
        .
        <div className="columns-2 md:columns-2 py-6">
          {talks.map((talk, index) => {
            return (
              <div
                className="p-1 transition-all duration-300 hover:scale-110 "
                key={index}
              >
                <a href={talk.imgSrc} target="_blank" rel="noopener noreferrer">
                  <img
                    src={talk.imgSrc}
                    alt="Photos taken during teaching or talk show"
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="uppercase pt-8 pb-2 font-bold">
        Wish I could be more frequent
      </div>
      <div className="text-md">
        With photography, I try to put story through the lens.
        <div className="columns-2 md:columns-2 py-6">
          {photos.map((photo, index) => {
            return (
              <div
                className="p-1 transition-all duration-300 hover:scale-110 "
                key={index}
              >
                <a
                  href={photo.imgSrc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={photo.imgSrc}
                    alt="photographs clicked by Aman Sanduja"
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="uppercase pt-8 pb-2 font-bold">I try my best</div>
      <div className="text-md">
        To invest in early-stage startups, with a bias towards emerging tech.
        I'm also interested in startups that serve the startup ecosystem and in
        capital-light, profit-focused startups.
      </div>
      <div className="uppercase pt-8 pb-2 font-bold">
        Always looking to interact with
      </div>
      <div className="text-md">
        Creators, thinkers, and tech enthusiasts chasing their dreams. We're
        mostly into technology, seeing it as our sanctuary to brainstorm and
        bring our ideas to life.
      </div>
    </div>
  );
}

export default Extra;
