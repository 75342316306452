// import React from "react";

// function Corporate() {
//   const works = [
//     {
//       where: "Functional Consultant at Employwise: ",
//       what: "Scaled client base from 17 to 32 in under 7 months, working on CRM.",
//     },
//     {
//       where: "Marketing Strategist at Google India: ",
//       what: "Onboarded 10+ partners, improved CSAT score, and introduced new features for Hotel Ads platform.",
//     },
//     {
//       where: "Tech Consultant at Piramal Foundation: ",
//       what: "Deployed technology and distributed 100+ tablets in rural schools of Gujarat, Maharashtra, and Rajasthan, reducing data sharing time from 3 weeks to less than 5 days.",
//     },
//     {
//       where: "Manager Officer of Outreach & Admissions at Ashoka University: ",
//       what: "Developed admissions strategy for India's first Liberal arts university.",
//     },
//     {
//       where: "Head of Business Development & Marketing at Poko (YC W22): ",
//       what: "Defined marketing strategy, onboarded 10+ DAOs for beta testing. Later sold to Web3auth.",
//     },
//     {
//       where:
//         "Head of Business Development at Slise.xyz (Alliance DAO & Binance Labs): ",
//       what: "Reached out to 400+ potential advertisers, built growth pipeline.",
//     },
//   ];
//   return (
//     <div>
//       <div className=" pt-8 pb-2 font-bold">CORPORATE GIG(s)</div>
//       <div className="text-md">
//         {works.map((work, index) => (
//           <div key={index}>
//             {work.where}
//             {work.what}
//             <br />
//             <br />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Corporate;

import React from "react";

function Corporate() {
  return (
    <div>
      <div className="pt-8 pb-2 font-bold">CORPORATE GIG(s)</div>
      <div className="text-md">
        With over 9 years of experience in both startups & corporates.
        <br />
        <br />
        <div>
          Functional Consultant at <span className="highlight">Employwise</span>
          : Scaled client base from 17 to 32 in under 7 months, working on CRM.
          <br />
          <br />
        </div>
        <div>
          Marketing Strategist at{" "}
          <span className="highlight">Google India</span>: Onboarded 10+
          partners, improved CSAT score, and introduced new features for Hotel
          Ads platform.
          <br />
          <br />
        </div>
        <div>
          Tech Consultant at{" "}
          <span className="highlight">Piramal Foundation</span>: Deployed
          technology and distributed 100+ tablets in rural schools of Gujarat,
          Maharashtra, and Rajasthan, reducing data sharing time from 3 weeks to
          less than 5 days.
          <br />
          <br />
        </div>
        <div>
          Manager Officer of Outreach & Admissions at{" "}
          <span className="highlight">Ashoka University</span>: Developed
          admissions strategy for India's first Liberal arts university.
          <br />
          <br />
        </div>
        <div>
          Head of Business Development & Marketing at{" "}
          <span className="highlight">Poko</span> (YC W22): Defined marketing
          strategy, onboarded 10+ DAOs for beta testing. Later sold to Web3auth.
          <br />
          <br />
        </div>
        <div>
          Head of Business Development at{" "}
          <span className="highlight">Slise.xyz</span> (Alliance DAO & Binance
          Labs): Reached out to 400+ potential advertisers, built growth
          pipeline.
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default Corporate;
